<template>
  <div class="d-inline">
    <div class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input shadow-none"
        id="customCheckFavorites"
        v-model="data.favorites"
        @change="set"
      />
      <label class="custom-control-label" for="customCheckFavorites"
        >в избраннное</label
      >
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "AddFavoritesRepair",
  props: ["data"],
  data: () => ({}),
  methods: {
    set() {
      HTTPGET({
        url: "repairs/add_favorites.php",
        params: {
          favorites: this.data.favorites,
          id: this.data.id
        }
      }).then(() => this.$emit("update"));
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-checkbox {
  display: inline-block;
  margin: 15px;
}
label {
  cursor: pointer;
}
</style>
