<template>
  <EditDataRepairsLayout>
    <div class="wrapper col-md-6">
      <table class="col-md-12">
        <tr>
          <td class="text-center">требование владельца</td>
        </tr>
        <tr>
          <td>
            <select
              class="form-control form-control-sm shadow-none"
              v-model="typeOfRepair"
              @change="changeTypeOfRepair"
            >
              <option value="repair">ремонт</option>
              <option value="noRepair">отказ от ремонта</option>
              <option value="diagnostiks">диагностика</option>
            </select>
          </td>
        </tr>
        <tr v-if="visibleOptionNoRepair">
          <td>
            <select
              class="form-control form-control-sm shadow-none"
              v-model="noRepairType"
              @click="changeNoRepairType"
            >
              <option value="1">отказ от ремонта в течении 15 дней</option>
              <option value="2"
                >отказ от ремонта в течении года только Леруа, Касторама и ОБИ
              </option>
              <option value="3">отсутствие запчастей для ремонта</option>
              <option value="4">другое</option>
            </select>
          </td>
        </tr>
        <tr v-if="visibleWorkCarried">
          <td class="text-center">
            {{
              typeOfRepair === "repair"
                ? "проведенные работы"
                : "результаты диагностики"
            }}
          </td>
        </tr>
        <tr>
          <td v-if="visibleWorkCarried">
            <textarea
              rows="3"
              class="form-control form-control-sm shadow-none"
              v-model.trim="workCarried"
              :placeholder="
                typeOfRepair === 'repair'
                  ? 'проведенные работы'
                  : 'результаты диагностики'
              "
              :class="errorWorkCarried ? 'is-invalid' : ''"
            ></textarea>
          </td>
        </tr>
        <tr v-if="visibleNoRepairOther">
          <td class="text-center">причина отказа от ремонта</td>
        </tr>
        <tr v-if="visibleNoRepairOther">
          <td>
            <textarea
              rows="3"
              class="form-control form-control-sm shadow-none"
              v-model.trim="noRepairTypeOther"
              placeholder="укажите причину отказа от ремонта"
              :class="errorNoRepairTypeOther ? 'is-invalid' : ''"
            ></textarea>
          </td>
        </tr>
      </table>

      <button class="btn btn-sm btn-outline-success shadow-none" @click="save">
        сохранить
      </button>
      <button class="btn btn-sm btn-outline-danger shadow-none" @click="cancel">
        отмена
      </button>
    </div>
  </EditDataRepairsLayout>
</template>
<script>
import EditDataRepairsLayout from "@/layouts/EditDataRepairsLayout.vue";
import { HTTPGET } from "@/api";
export default {
  name: "EditRepairOptions",
  components: {
    EditDataRepairsLayout
  },
  props: ["data"],
  data: () => ({
    typeOfRepair: null,
    visibleOptionNoRepair: false,
    workCarried: null,
    visibleWorkCarried: false,
    visibleNoRepairOther: false,
    noRepairTypeOther: null,
    noRepairType: null,
    errorWorkCarried: false,
    errorNoRepairTypeOther: false,
    error: true
  }),
  methods: {
    changeTypeOfRepair() {
      if (this.typeOfRepair === "repair") {
        this.visibleWorkCarried = true;
        this.visibleOptionNoRepair = false;
        this.visibleNoRepairOther = false;
        if (!this.workCarried) {
          this.errorWorkCarried = true;
        }
      }
      if (this.typeOfRepair === "noRepair") {
        this.visibleWorkCarried = false;
        this.visibleOptionNoRepair = true;
        this.noRepairType = "1";
        if (this.noRepairType === "4") {
          this.visibleNoRepairOther = true;
        }
      }
      if (this.typeOfRepair === "diagnostiks") {
        this.visibleNoRepairOther = false;
        this.visibleWorkCarried = true;
        this.visibleOptionNoRepair = false;
        if (!this.workCarried) {
          this.errorWorkCarried = true;
        }
      }
    },
    changeNoRepairType() {
      if (this.noRepairType === "4") {
        this.visibleNoRepairOther = true;
        if (!this.noRepairTypeOther) {
          this.errorNoRepairTypeOther = true;
        }
      } else {
        this.visibleNoRepairOther = false;
      }
    },
    save() {
      this.error = true;
      if (
        (this.typeOfRepair === "repair" ||
          this.typeOfRepair === "diagnostiks") &&
        this.workCarried &&
        this.workCarried
      ) {
        this.noRepairTypeOther = null;
        this.noRepairType = null;
        this.error = false;
      }
      if (
        this.typeOfRepair === "noRepair" &&
        this.noRepairType === "4" &&
        this.noRepairTypeOther &&
        this.noRepairTypeOther
      ) {
        this.workCarried = null;
        this.error = false;
      }
      if (this.typeOfRepair === "noRepair" && this.noRepairType !== "4") {
        this.workCarried = null;
        this.noRepairTypeOther = null;
        this.error = false;
      }
      if (this.error) {
        return;
      }
      HTTPGET({
        url: "repairs/repair_edit/edit_repair_options.php",
        params: {
          workCarried: this.workCarried,
          typeOfRepair: this.typeOfRepair,
          noRepairTypeOther: this.noRepairTypeOther,
          noRepairType: this.noRepairType,
          id: this.data.remont_id
        }
      }).then(() => this.$emit("cancel"));
    },
    cancel() {
      this.$emit("cancel");
    }
  },
  mounted() {
    this.workCarried = this.data.workCarried;
    this.typeOfRepair = this.data.typeOfRepair;
    this.noRepairTypeOther = this.data.noRepairTypeOther;
    this.noRepairType = this.data.noRepairType;
    if (this.typeOfRepair !== "noRepair") {
      this.visibleWorkCarried = true;
    } else {
      this.visibleWorkCarried = false;
      this.visibleOptionNoRepair = true;
    }
    if (this.noRepairType === "4") {
      this.visibleNoRepairOther = true;
    }
  },
  watch: {
    noRepairTypeOther() {
      if (this.noRepairTypeOther) {
        this.errorNoRepairTypeOther = false;
      } else {
        this.errorNoRepairTypeOther = true;
      }
    },
    workCarried() {
      if (this.workCarried) {
        this.errorWorkCarried = false;
      } else {
        this.errorWorkCarried = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
td {
  padding: 3px;
}
.wrapper {
  padding: 15px;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 3px;
  display: table;
  margin: auto;
}
.btn {
  margin-right: 15px;
  margin-top: 10px;
}
</style>
