<template>
  <div class="foto-wrapper">
    <div class="path">
      <span v-for="(item, idx) in PATH" :key="idx">{{ item }}</span>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <div v-if="data.dat">
          <div class="wq">
            <RepairOrderNumber :order_id="data.dat.order_id" />
          </div>
          <div v-if="USER_INFO.status === 'admin'">
            <a href="" @click.prevent="copy"
              >скопировать ссылку на ремонт для мастерской</a
            >
            <AddConsultationRepair :data="data.dat" @update="updateData" />
            <div
              v-if="data.dat.consultation_messages || data.dat.consultation"
              class="m-1 small"
            >
              <div class="text-center" v-if="data.dat.consultation_messages">
                <b>переписка с мастерской</b>
              </div>
              <div>
                <div>
                  <table class="table table-sm">
                    <tbody>
                      <tr
                        v-for="(item, idx) in data.dat.consultation_messages"
                        :key="idx + 'cons'"
                      >
                        <td>{{ item.data }}</td>
                        <td>{{ item.user }}</td>
                        <td>{{ item.message }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <textarea
                  class="form-control form-control-sm input-form shadow-none m-1"
                  rows="1"
                  v-model.trim="messageForMaster"
                  placeholder="сообщение для мастерской"
                ></textarea>
                <button
                  :disabled="!messageForMaster.length"
                  type="button"
                  class="btn btn-sm btn-outline-primary m-1 shadow-none"
                  @click="sendMessageForMaster"
                >
                  отправить
                </button>
              </div>
            </div>
          </div>
          <div class="form-row overflow">
            <table class="table table-sm">
              <tbody>
                <tr class="nocursor">
                  <td colspan="3">
                    <button
                      class="back btn btn-sm btn-outline-secondary shadow-none"
                      @click="back"
                    >
                      <i class="bi bi-arrow-left"></i> назад
                    </button>
                    <button
                      v-if="
                        data.dat.no_edit === '0' &&
                          data.dat.allow_temp_edit === '1' &&
                          data.dat.typeOfRepair === 'repair'
                      "
                      class="btn btn-sm btn-outline-danger shadow-none"
                      @click="deleteRepair"
                    >
                      удалить
                    </button>
                    <NoGuarantee
                      v-if="data.dat && data.dat.typeOfRepair !== 'noRepair'"
                      :data="data.dat"
                      @update="updateData"
                    />
                    <AddFavoritesRepair
                      v-if="data.dat && USER_INFO.status === 'admin'"
                      :data="data.dat"
                      @update="updateData"
                    />
                    <span v-if="USER_INFO.status === 'admin'"
                      ><AllowEditingRepair
                        v-if="data.dat.no_edit && data.dat.order_id"
                        :edit="data.dat.no_edit"
                        @update="updateData"
                    /></span>
                  </td>
                </tr>
                <tr class="nocursor">
                  <td colspan="3">
                    <OneRepairActions
                      v-if="data.dat"
                      :data="data.dat"
                      @update="get_data"
                    />
                  </td>
                </tr>
                <tr class="nocursor">
                  <td class="col-3">СЦ</td>
                  <td>{{ data.dat.user }}</td>
                  <td></td>
                </tr>
                <tr @click="editRepairCtegory">
                  <td>категория ремонта</td>
                  <td>{{ data.dat.repair_category }}</td>
                  <td>
                    <i
                      class="bi bi-pencil-square"
                      @click="editRepairCtegory"
                    ></i>
                  </td>
                </tr>
                <tr class="nocursor">
                  <td>стоимость работ</td>
                  <td @click="editRepairPrice">
                    {{
                      data.dat.repair_price
                        ? data.dat.repair_price + " р."
                        : "стоимость работ появится после выдачи инструмента и оценки ремонта"
                    }}
                  </td>
                  <td></td>
                </tr>
                <tr class="nocursor">
                  <td>стоимость запчастей</td>
                  <td>
                    {{ data.summzap + " р." }}
                  </td>
                  <td></td>
                </tr>
                <tr class="nocursor">
                  <td>номер ремонта</td>
                  <td>{{ data.dat.repair_number }}</td>
                  <td></td>
                </tr>
                <tr @click="edit('VisibleEditInstr')">
                  <td>наименование изделия</td>
                  <td>{{ data.dat.name_instr }}</td>
                  <td>
                    <i
                      class="bi bi-pencil-square"
                      @click="edit('VisibleEditInstr')"
                    ></i>
                  </td>
                </tr>
                <tr @click="editSeriaNumber">
                  <td>буквы серийного номера</td>
                  <td>{{ data.dat.serialNumberGroup }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editSeriaNumber"></i>
                  </td>
                </tr>
                <tr @click="editSeriaNumber">
                  <td>серийный номер</td>
                  <td>{{ data.dat.serialNumber }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editSeriaNumber"></i>
                  </td>
                </tr>
                <tr @click="editSeriaNumber">
                  <td>серийный номер отсутствует</td>
                  <td>{{ data.dat.notSerialNumber === "1" ? "да" : "нет" }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editSeriaNumber"></i>
                  </td>
                </tr>
                <tr @click="editDataSale">
                  <td>предторг</td>
                  <td>{{ data.dat.predtorg === "1" ? "да" : "нет" }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editDataSale"></i>
                  </td>
                </tr>
                <tr @click="editDataSale">
                  <td>дата продажи</td>
                  <td>{{ data.dat.dateSale }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editDataSale"></i>
                  </td>
                </tr>
                <tr @click="editDataSale">
                  <td>дата принятия в ремонт</td>
                  <td>{{ data.dat.dateRepair }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editDataSale"></i>
                  </td>
                </tr>
                <tr class="nocursor">
                  <td>дата создания</td>
                  <td>{{ data.dat.date_add }}</td>
                  <td></td>
                </tr>
                <tr
                  v-if="data.dat.repair_confirm"
                  @click="editRepairIsOverData"
                  :class="USER_INFO.status !== 'admin' && 'nocursor'"
                >
                  <td>дата выдачи</td>
                  <td>{{ data.dat.repair_is_over_data }}</td>
                  <td>
                    <i
                      v-if="USER_INFO.status === 'admin'"
                      class="bi bi-pencil-square"
                      @click="editDataSale"
                    ></i>
                  </td>
                </tr>
                <tr @click="editSeller">
                  <td>продавец</td>
                  <td>{{ data.dat.seller }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editSeller"></i>
                  </td>
                </tr>
                <tr @click="editDefect">
                  <td>дефект</td>
                  <td>{{ data.dat.defect }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editDefect"></i>
                  </td>
                </tr>
                <tr @click="editRepairOptions">
                  <td>требование владельца</td>
                  <td>{{ typeIs(data.dat.typeOfRepair) }}</td>
                  <td>
                    <i
                      class="bi bi-pencil-square"
                      @click="editRepairOptions"
                    ></i>
                  </td>
                </tr>
                <tr v-if="data.dat.typeOfRepair === 'noRepair'">
                  <td>причина отказа</td>
                  <td>{{ typeOfRepair(data.dat.typeOfRepair) }}</td>
                  <td></td>
                </tr>
                <tr @click="editRepairOptions">
                  <td>проведенные работы</td>
                  <td>{{ data.dat.workCarried }}</td>
                  <td>
                    <i
                      class="bi bi-pencil-square"
                      @click="editRepairOptions"
                    ></i>
                  </td>
                </tr>
                <tr @click="editEditReceiptNumber">
                  <td>номер квитанции СЦ</td>
                  <td>{{ data.dat.receiptNumber }}</td>
                  <td>
                    <i
                      class="bi bi-pencil-square"
                      @click="editEditReceiptNumber"
                    ></i>
                  </td>
                </tr>
                <tr @click="editComment">
                  <td>комментарий</td>
                  <td>{{ data.dat.comment }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editComment"></i>
                  </td>
                </tr>
                <tr v-if="data.zap">
                  <td colspan="3">
                    <ZapTable
                      :data="data.zap"
                      title="запчасти Калибра"
                      @editZap="editZap('')"
                      :ostatok="true"
                      :price="true"
                      :number_nakl="true"
                    />
                  </td>
                </tr>
                <tr v-if="data.user_zap">
                  <td colspan="3">
                    <ZapTable
                      :data="data.user_zap"
                      title="запчасти Калибра из ранее приобретенных"
                      @editZap="editZap('')"
                      :cena="true"
                    />
                  </td>
                </tr>
                <tr v-if="data.zap_sc && data.zap_sc.length">
                  <td colspan="3">
                    <ZapTable
                      :data="data.zap_sc"
                      title="собственные запчасти СЦ"
                      @editZap="editZap('zap_sc')"
                      :customField="true"
                    />
                  </td>
                </tr>
                <tr>
                  <td colspan="4">
                    <button
                      v-if="
                        data.dat.typeOfRepair === 'repair' &&
                          !data.user_zap &&
                          !data.zap
                      "
                      class="btn btn-sm btn-outline-success shadow-none m10"
                      @click="editZap"
                    >
                      добавить запчасти Калибра
                    </button>
                    <button
                      v-if="
                        data.dat.typeOfRepair === 'repair' &&
                          data.zap_sc &&
                          !data.zap_sc.length
                      "
                      class="btn btn-sm btn-outline-secondary shadow-none"
                      @click="editZapSC"
                    >
                      добавить собственные запчасти СЦ
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="chat">
            <Chat
              :key="chatkey"
              urlSendMessage="repairs/chat/chat_of_repair.php"
              urlDeleteMessage="repairs/chat/del_message.php"
              chatId="chat_of_repair"
              title="переписка по ремонту"
            />
          </div>
          <div v-if="USER_INFO.status === 'admin'">
            <EditHistory :key="history_key" />
          </div>
        </div>
        <div v-else />
      </div>
      <div class="form-group col-md-6" id="one-repair-right">
        <PhotoWrapper
          :dataPhoto="data.labelFoto"
          :data="data.dat"
          type="labelFoto"
          title="фото шильдика с серийным номером"
          @update="get_data"
          @addFile="addFile"
        />
        <PhotoWrapper
          :dataPhoto="data.ticketFoto"
          :data="data.dat"
          type="ticketFoto"
          title="фото гарантийного талона в паспорте изделия"
          @update="get_data"
          @addFile="addFile"
        />
        <PhotoWrapper
          :dataPhoto="data.viewFoto"
          :data="data.dat"
          type="viewFoto"
          title="фото внешнего вида изделия"
          @update="get_data"
          @addFile="addFile"
        />
        <PhotoWrapper
          :dataPhoto="data.defectDetailFoto"
          :data="data.dat"
          type="defectDetailFoto"
          title="фото дефекта, дефектных деталей"
          @update="get_data"
          @addFile="addFile"
        />
        <PhotoWrapper
          :dataPhoto="data.dataAdditionalFoto"
          :data="data.dat"
          type="dataAdditionalFoto"
          title="дополнительные материалы"
          @update="get_data"
          @addFile="addFile"
        />
      </div>
    </div>
    <EditInstr
      v-if="VisibleEditInstr"
      :data="data.dat"
      @cancelEditInstr="cancelEditInstr"
    />
    <div v-else />
    <AddFileForRepair
      v-if="title"
      :typeFileFoto="typeFileFoto"
      :title="title"
      :remont_id="data.dat.remont_id"
      :type="type"
      @addFileClose="addFileClose"
    />
    <div v-else />
    <EditSerialNumber
      v-if="visibleEditSerialNumber"
      :data="data.dat"
      @close="cancelEditSerialNumber"
    />
    <div v-else />
    <EditDataSale
      v-if="visibleEditDataSale"
      :data="data.dat"
      @cancel="cancelEditDataSale"
    />
    <div v-else />
    <EditSeller
      v-if="visibleEditSeller"
      :data="data.dat"
      @cancel="cancelEditSeller"
    />
    <div v-else />
    <EditDefect
      v-if="visibleEditDefect"
      :data="data.dat"
      @cancel="cancelEditDefect"
    />
    <div v-else />
    <EditRepairCategory
      v-if="visibleEditRepairCategory"
      :data="data.dat"
      @cancel="cancelEditRepairCategory"
    />
    <div v-else />
    <EditRepairOptions
      v-if="visibleEditRepairOptions"
      :data="data.dat"
      @cancel="cancelEditRepairOptions"
    />
    <div v-else />
    <EditReceiptNumber
      v-if="visibleEditReceiptNumber"
      :data="data.dat"
      @cancel="cancelEditReceiptNumber"
    />
    <div v-else />
    <EditComment
      v-if="visibleEditComment"
      :data="data.dat"
      @cancel="cancelEditComment"
    />
    <div v-else />
    <EditZap
      v-if="visibleEditZap"
      :data="data"
      @cancel="cancelEditZap"
      @updateData="updateData"
    />
    <div v-else />
    <EditZapSC v-if="visibleEditZapSC" :data="data" @cancel="cancelEditZapSC" />
    <div v-else />
    <EditPrice
      v-if="visibleEditPrice"
      :data="data.dat"
      @cancel="cancelEditPrice"
    />
    <div v-else />
    <EditRepairIsOverData
      v-if="visibleEditRepairIsOverData"
      :data="data.dat"
      @cancel="cancelEditRepairIsOverData"
    />
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import PhotoWrapper from "@/components/PhotoWrapper.vue";
import OneRepairActions from "@/components/repairs/OneRepairActions.vue";
import NoGuarantee from "@/components/repairs/NoGuarantee.vue";
import ZapTable from "@/components/repairs/ZapTable.vue";
import EditInstr from "@/views/repairs/editRepair/EditInstr.vue";
import AddFileForRepair from "@/views/repairs/editRepair/AddFileForRepair.vue";
import EditSerialNumber from "@/views/repairs/editRepair/EditSerialNumber.vue";
import EditDataSale from "@/views/repairs/editRepair/EditDataSale.vue";
import EditSeller from "@/views/repairs/editRepair/EditSeller.vue";
import EditDefect from "@/views/repairs/editRepair/EditDefect.vue";
import EditRepairOptions from "@/views/repairs/editRepair/EditRepairOptions.vue";
import EditReceiptNumber from "@/views/repairs/editRepair/EditReceiptNumber.vue";
import EditComment from "@/views/repairs/editRepair/EditComment.vue";
import EditZap from "@/views/repairs/editRepair/EditZap.vue";
import EditZapSC from "@/views/repairs/editRepair/EditZapSC.vue";
import EditRepairCategory from "@/views/repairs/editRepair/EditRepairCategory.vue";
import EditPrice from "@/views/repairs/editRepair/EditPrice.vue";
import AllowEditingRepair from "@/views/repairs/editRepair/AllowEditingRepair.vue";
import EditRepairIsOverData from "@/views/repairs/editRepair/EditRepairIsOverData.vue";
import Chat from "@/views/repairs/chat/Chat.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import EditHistory from "./editRepair/EditHistory.vue";
import RepairOrderNumber from "@/components/repairs/RepairOrderNumber.vue";
import AddFavoritesRepair from "@/components/repairs/AddFavoritesRepair.vue";
import AddConsultationRepair from "@/components/repairs/AddConsultationRepair.vue";
export default {
  name: "OneRepair",
  components: {
    PhotoWrapper,
    EditInstr,
    AddFileForRepair,
    EditSerialNumber,
    EditDataSale,
    EditSeller,
    EditDefect,
    EditRepairOptions,
    EditReceiptNumber,
    EditComment,
    EditZap,
    Chat,
    OneRepairActions,
    NoGuarantee,
    EditRepairCategory,
    ZapTable,
    EditZapSC,
    EditPrice,
    AllowEditingRepair,
    EditHistory,
    RepairOrderNumber,
    EditRepairIsOverData,
    AddFavoritesRepair,
    AddConsultationRepair
  },
  data: () => ({
    data: {
      dat: {},
      labelFoto: {},
      ticketFoto: {},
      viewFoto: {},
      defectDetailFoto: {},
      dataAdditionalFoto: {}
    },
    VisibleEditInstr: false,
    title: null,
    typeFileFoto: null,
    type: null,
    visibleEditSerialNumber: false,
    visibleEditDataSale: false,
    visibleEditSeller: false,
    visibleEditDefect: false,
    visibleEditRepairOptions: false,
    visibleEditReceiptNumber: false,
    visibleEditComment: false,
    visibleEditZap: false,
    visibleEditRepairCategory: false,
    visibleEditZapSC: false,
    visibleEditPrice: false,
    visibleEditRepairIsOverData: false,
    chatkey: 0,
    history_key: 0,
    messageForMaster: ""
  }),
  methods: {
    ...mapActions(["PUSHPATH"]),
    ...mapMutations(["oldZap"]),
    editZapSC() {
      this.visibleEditZapSC = true;
    },
    cancelEditZapSC() {
      this.get_data();
      this.visibleEditZapSC = false;
    },
    deleteRepair() {
      confirm(
        "Все данные и файлы относящиеся к данному ремонту будут удалены!!! Удалять?"
      )
        ? HTTPGET({
            url: "repairs/repair_edit/delete_repair.php",
            params: {
              id: this.$route.params.id
            }
          }).then(() => this.$router.push("/repairs/repairs-list"))
        : null;
    },
    updateData() {
      this.get_data();
    },
    get_data() {
      HTTPGET({
        url: "repairs/get_one_repair.php",
        params: {
          id: this.$route.params.id
        }
      })
        .then(resp => (this.data = resp.data))
        .then(() => {
          if (!this.data.zap_sc) {
            this.data.zap_sc = [];
          }
        })
        .then(() => this.PUSHPATH(`ремонт №${this.data.dat.repair_number}`))
        .then(() => this.oldZap(this.data.user_zap))
        .then(() => (document.title = `ремонт №${this.data.dat.repair_number}`))
        .then(() => this.who_sawe())
        .then(() => (this.history_key += 1));
    },
    typeOfRepair(type) {
      if (type === "noRepair") {
        if (this.data.dat.noRepairType === "1") {
          return "отказ от ремонта в течении 15 дней";
        }
      }
      if (type === "noRepair") {
        if (this.data.dat.noRepairType === "2") {
          return "отказ от ремонта в течении года только Леруа, Касторама и ОБИ";
        }
      }
      if (type === "noRepair") {
        if (this.data.dat.noRepairType === "3") {
          return "отсутствие запчастей для ремонта";
        }
      }
      if (type === "noRepair") {
        if (this.data.dat.noRepairType === "4") {
          return this.data.dat.noRepairTypeOther;
        }
      }
    },
    typeIs(type) {
      if (type === "repair") {
        return "ремонт";
      }
      if (type === "diagnostiks") {
        return "диагностика";
      }
      if (type === "noRepair") {
        return "отказ от ремонта";
      }
    },
    edit(field) {
      this[field] = true;
    },
    addFile(data) {
      this.type = data.type;
      this.title = data.title;
      if (data.type !== "dataAdditionalFoto") {
        this.typeFileFoto = /\.(jpe?g|png|gif)$/i;
      }
      if (data.type === "dataAdditionalFoto") {
        this.typeFileFoto = /\.(jpe?g|png|gif|avi|mp4|mov|mp3|xls|xlsx|pdf|docx|doc)$/i;
      }
    },
    addFileClose() {
      this.title = null;
      this.typeFileFoto = null;
      this.type = null;
      this.get_data();
    },
    cancelEditInstr() {
      this.VisibleEditInstr = false;
      this.get_data();
    },
    editSeriaNumber() {
      this.visibleEditSerialNumber = true;
    },
    cancelEditSerialNumber() {
      this.visibleEditSerialNumber = false;
      this.get_data();
    },
    editDataSale() {
      this.visibleEditDataSale = true;
    },
    cancelEditDataSale() {
      this.get_data();
      this.visibleEditDataSale = false;
    },
    editSeller() {
      this.visibleEditSeller = true;
    },
    cancelEditSeller() {
      this.get_data();
      this.visibleEditSeller = false;
    },
    editDefect() {
      this.visibleEditDefect = true;
    },
    cancelEditDefect() {
      this.get_data();
      this.visibleEditDefect = false;
    },
    editRepairOptions() {
      this.visibleEditRepairOptions = true;
    },
    cancelEditRepairOptions() {
      this.get_data();
      this.visibleEditRepairOptions = false;
    },
    editEditReceiptNumber() {
      this.visibleEditReceiptNumber = true;
    },
    cancelEditReceiptNumber() {
      this.get_data();
      this.visibleEditReceiptNumber = false;
    },
    editComment() {
      this.visibleEditComment = true;
    },
    cancelEditComment() {
      this.get_data();
      this.visibleEditComment = false;
    },
    editRepairIsOverData() {
      if (this.USER_INFO.status === "admin") {
        this.visibleEditRepairIsOverData = true;
      }
    },
    cancelEditRepairIsOverData() {
      this.get_data();
      this.visibleEditRepairIsOverData = false;
    },
    editZap(f) {
      if (f === "zap_sc") {
        this.visibleEditZapSC = true;
      } else {
        this.visibleEditZap = true;
      }
    },
    cancelEditZap() {
      this.get_data();
      this.visibleEditZap = false;
    },
    editRepairCtegory() {
      this.visibleEditRepairCategory = true;
    },
    cancelEditPrice() {
      this.visibleEditPrice = false;
      this.get_data();
      this.$router.go(-1);
    },
    cancelEditRepairCategory() {
      this.get_data();
      this.visibleEditRepairCategory = false;
    },
    back() {
      if (this.GET_PATH_FROM === "/login") {
        this.$router.push("/home");
      } else {
        this.$router.go(-1);
      }
    },
    editRepairPrice() {
      if (
        (this.USER_INFO.gruppa === "super" ||
          this.USER_INFO.gruppa === "otchet") &&
        this.data.dat.repair_is_over
      ) {
        this.visibleEditPrice = true;
      }
    },
    who_sawe() {
      HTTPGET({
        url: "repairs/who_saw.php",
        params: {
          id: this.$route.params.id,
          add: true
        }
      });
    },
    copy() {
      navigator.clipboard
        .writeText(`http://0.ru/#/repairs/?id=${this.data.dat.id}`)
        .then(() => {
          alert("ссылка скопированна в буфер обмена");
        })
        .catch(err => {
          alert("поизошла ошибка", err);
        });
    },
    sendMessageForMaster() {
      HTTPGET({
        url: "repairs/add_message_for_consultation.php",
        params: {
          id: this.data.dat.id,
          message: this.messageForMaster,
          master_or_menedger: "menedger"
        }
      }).then(() => {
        this.get_data(), (this.messageForMaster = "");
      });
    },
    hide_message_consultation() {}
  },
  mounted() {
    this.get_data();
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapGetters(["USER_INFO", "GET_PATH_FROM", "PATH"])
  },
  watch: {
    $route() {
      this.get_data();
      this.chatkey = this.chatkey + 1;
    }
  }
};
</script>
<style lang="scss" scoped>
img {
  width: 95%;
}
.foto-row {
  justify-content: center;
}
.foto-wrapper {
  padding: 10px;
  width: 100%;
}
.overflow {
  overflow: auto;
}
table {
  font-size: 0.9em;
}
#one-repair-right {
  height: 80vh;
  overflow: auto;
}
i,
tr {
  cursor: pointer;
}
.nocursor {
  cursor: default;
}
.back {
  margin-right: 5%;
}
.m10 {
  margin-right: 10px;
}
.path {
  margin-left: 10px;
  font-size: 0.7em;
  color: rgb(168, 180, 180);
}
.wq {
  height: 1.5em;
}
td {
  vertical-align: middle;
}
</style>
