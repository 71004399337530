<template>
  <div v-if="list" class="mt-2">
    <div class="text-center">журнал изменений</div>
    <table class="table table-sm">
      <tr>
        <th>дата</th>
        <th>пользователь</th>
        <th>что изменено</th>
        <th>старое значение</th>
        <th>новое значение</th>
      </tr>
      <tr v-for="(item, idx) in list.history" :key="idx">
        <td>{{ item.date_edit }}</td>
        <td>{{ item.user }}</td>
        <td>{{ item.type }}</td>
        <td>{{ item.old_value }}</td>
        <td>{{ item.new_value }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "EditHistory",
  data: () => ({
    list: null
  }),
  mounted() {
    this.get_data();
  },
  methods: {
    get_data() {
      HTTPGET({
        url: "/admin/log/get_edit_repair_history.php",
        params: {
          id: this.$route.params.id
        }
      }).then(response => (this.list = response.data));
    }
  }
};
</script>
<style scoped>
table {
  font-size: 0.7em;
}
td {
  vertical-align: middle;
}
</style>
