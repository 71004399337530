<template>
  <EditDataRepairsLayout>
    <div class="wrapper col-md-6 text-center">
      <table class="col-md-12">
        <tbody>
          <tr>
            <td>дефект</td>
            <td>
              <textarea
                rows="3"
                class="form-control form-control-sm input-form shadow-none"
                v-model.trim="defect"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-sm btn-outline-success shadow-none" @click="save">
        сохранить
      </button>
      <button class="btn btn-sm btn-outline-danger shadow-none" @click="cancel">
        отмена
      </button>
    </div>
  </EditDataRepairsLayout>
</template>
<script>
import EditDataRepairsLayout from "@/layouts/EditDataRepairsLayout.vue";
import { HTTPGET } from "@/api";
export default {
  name: "EditDefect",
  props: ["data"],
  components: {
    EditDataRepairsLayout
  },
  data: () => ({
    defect: null
  }),
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      if (this.defect === this.data.defect) {
        this.$emit("cancel");
        return;
      }
      if (this.defect === "") {
        alert("это поле не может быть пустым");
        this.defect = "";
      } else {
        HTTPGET({
          url: "repairs/repair_edit/edit_repair_defect.php",
          params: {
            defect: this.defect,
            id: this.data.remont_id
          }
        }).then(() => this.$emit("cancel"));
      }
    }
  },
  mounted() {
    this.defect = this.data.defect;
  }
};
</script>
<style lang="scss" scoped>
table {
  margin: auto;
}
td {
  padding: 3px;
}
.wrapper {
  padding: 15px;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 3px;
  display: table;
  margin: auto;
}
.btn {
  margin-right: 15px;
  margin-top: 10px;
}
</style>
