<template>
  <div>
    <EditDataRepairsLayout>
      <div class="wrapper">
        <table>
          <tbody>
            <tr>
              <td>предторг</td>
              <td>
                <input
                  type="checkbox"
                  class="form-control form-control-sm input-form shadow-none checkbox"
                  v-model="checkbox"
                  @input="editcheckbox"
                />
              </td>
            </tr>
            <tr>
              <td>дата продажи</td>
              <td>
                <DatePicker
                  v-model="dateSale"
                  valueType="YYYY-MM-DD"
                  @input="editDate"
                ></DatePicker>
              </td>
            </tr>
            <tr>
              <td>дата принятия в ремонт</td>
              <td>
                <DatePicker
                  v-model="dateRepair"
                  valueType="YYYY-MM-DD"
                  @input="editDateRepair"
                ></DatePicker>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <button
            class="btn btn-sm btn-outline-success shadow-none"
            @click="save"
          >
            сохранить
          </button>
          <button
            class="btn btn-sm btn-outline-danger shadow-none"
            @click="cancel"
          >
            отмена
          </button>
        </div>
      </div>
    </EditDataRepairsLayout>
  </div>
</template>
<script>
import EditDataRepairsLayout from "@/layouts/EditDataRepairsLayout.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";
import { HTTPGET } from "@/api";
export default {
  name: "EditDataSale",
  props: ["data"],
  components: {
    EditDataRepairsLayout,
    DatePicker
  },
  computed: {
    foo() {
      return this.dateRepair;
    },
    foo2() {
      return this.dateSale;
    }
  },
  watch: {
    foo() {
      if (this.dateSale !== null && this.dateRepair !== null) {
        const date1 = new Date(this.dateSale);
        const date2 = new Date(this.dateRepair);
        if (!(date1 <= date2)) {
          alert(
            "дата принятия в ремонт не может быть меньше чем дата продажи!"
          );
          this.dateRepair = this.data.dateRepair2;
        }
      }
    },
    foo2() {
      if (this.dateSale !== null && this.dateRepair !== null) {
        const date1 = new Date(this.dateSale);
        const date2 = new Date(this.dateRepair);
        if (!(date1 <= date2)) {
          alert("дата продажи не может быть больше чем дата ремонта!");
          this.dateSale = this.data.dateSale2;
        }
      }
    }
  },
  data: () => ({
    checkbox: null,
    dateSale: null,
    dateRepair: null
  }),
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    editcheckbox() {
      if (!this.checkbox) {
        this.dateSale = null;
      } else {
        this.dateSale = this.data.dateSale2;
        this.checkbox = true;
      }
      if (this.checkbox && !this.dateSale) {
        this.dateSale = this.dateNow();
        this.checkbox = null;
      }
    },
    editDate() {
      if (this.dateSale !== null) {
        this.checkbox = null;
      } else {
        this.checkbox = true;
      }
    },
    dateNow() {
      const date = new Date();
      let month = date.getMonth() + 1;
      month = month.toString().padStart(2, "0");
      let day = date.getDate();
      day = day.toString().padStart(2, "0");
      const newDate = `${date.getFullYear()}-${month}-${day}`;
      return newDate;
    },
    editDateRepair() {
      if (this.dateRepair === null) {
        this.dateRepair = this.dateNow();
      }
    },
    save() {
      if (this.checkbox === false) {
        this.checkbox = null;
      }
      if (
        this.dateSale === this.data.dateSale2 &&
        this.dateRepair === this.data.dateRepair2
      ) {
        this.$emit("cancel");
      } else {
        HTTPGET({
          url: "repairs/repair_edit/edit_repair_data_sale.php",
          params: {
            dateSale: this.dateSale,
            predtorg: this.checkbox,
            dateRepair: this.dateRepair,
            id: this.data.remont_id
          }
        }).then(() => this.$emit("cancel"));
      }
    }
  },
  mounted() {
    if (this.data.predtorg === "0" || this.data.predtorg === null) {
      this.checkbox = null;
    } else {
      this.checkbox = true;
    }
    this.dateSale = this.data.dateSale2;
    this.dateRepair = this.data.dateRepair2;
  }
};
</script>
<style lang="scss" scoped>
.checkbox {
  height: 1.2em;
  width: 1.2em;
}
.wrapper {
  padding: 15px;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 3px;
  display: table;
  margin: auto;
}
.btn {
  margin-right: 15px;
  margin-top: 10px;
}
</style>
