<template>
  <EditDataRepairsLayout>
    <div class="wrapper">
      <table>
        <tbody>
          <tr>
            <td>номер квитанции СЦ</td>
            <td>
              <input
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                v-model="receiptNumber"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-sm btn-outline-success shadow-none" @click="save">
        сохранить
      </button>
      <button class="btn btn-sm btn-outline-danger shadow-none" @click="cancel">
        отмена
      </button>
    </div>
  </EditDataRepairsLayout>
</template>
<script>
import EditDataRepairsLayout from "@/layouts/EditDataRepairsLayout.vue";
import { HTTPGET } from "@/api";
export default {
  name: "EditReceiptNumber",
  props: ["data"],
  components: {
    EditDataRepairsLayout
  },
  data: () => ({
    receiptNumber: ""
  }),
  mounted() {
    this.receiptNumber = this.data.receiptNumber;
  },
  methods: {
    save() {
      if (this.receiptNumber === "") {
        this.receiptNumber = null;
      }
      HTTPGET({
        url: "repairs/repair_edit/edit_receipt_mumber.php",
        params: {
          receiptNumber: this.receiptNumber,
          id: this.data.remont_id
        }
      }).then(() => this.$emit("cancel"));
    },
    cancel() {
      this.$emit("cancel");
    }
  }
};
</script>
<style lang="scss" scoped>
td {
  padding: 3px;
}
.wrapper {
  padding: 15px;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 3px;
  display: table;
  margin: auto;
}
.btn {
  margin-right: 15px;
  margin-top: 10px;
}
</style>
