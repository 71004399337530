import { render, staticRenderFns } from "./EditPrice.vue?vue&type=template&id=15d6ca59&scoped=true&"
import script from "./EditPrice.vue?vue&type=script&lang=js&"
export * from "./EditPrice.vue?vue&type=script&lang=js&"
import style0 from "./EditPrice.vue?vue&type=style&index=0&id=15d6ca59&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d6ca59",
  null
  
)

export default component.exports