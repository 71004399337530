<template>
  <EditDataRepairsLayout>
    <div class="wrapper col-md-6 text-center">
      <table class="table table-sm">
        <tr>
          <th colspan="4">собственные запчасти СЦ</th>
        </tr>
        <tr>
          <th>наименование</th>
          <th>шт.</th>
          <th>цена</th>
          <th></th>
        </tr>
        <tr v-for="(item, idx) in zzz" :key="idx">
          <td>
            <input
              v-model="zzz[idx].name"
              type="text"
              placeholder="наименование"
              class="form-control form-control-sm input-form shadow-none"
              :class="zzz[idx].name.length < 1 ? 'is-invalid' : ''"
            />
          </td>
          <td class="w4">
            <input
              v-model="zzz[idx].pcs"
              type="number"
              min="1"
              placeholder="количество"
              class="form-control form-control-sm input-form shadow-none"
              :class="zzz[idx].pcs.length < 1 ? 'is-invalid' : ''"
            />
          </td>
          <td class="w10">
            <input
              v-model="zzz[idx].cena"
              type="number"
              min="1"
              placeholder="цена"
              class="form-control form-control-sm input-form shadow-none"
              :class="zzz[idx].cena.length < 1 ? 'is-invalid' : ''"
            />
          </td>
          <td><i class="bi bi-x" @click="deleteZapSC(idx)"></i></td>
        </tr>
      </table>

      <button @click="add" class="btn btn-sm btn-outline-primary shadow-none">
        добавить запчасть
      </button>
      <button @click="save" class="btn btn-sm btn-outline-success shadow-none">
        сохранить
      </button>
      <button @click="cancel" class="btn btn-sm btn-outline-danger shadow-none">
        отмена
      </button>
    </div>
    <ModalMessage
      v-if="visibleMessage"
      message="все поля выделенные красным должны быть заполнены!"
      @hideMessage="hideMessage"
    />
  </EditDataRepairsLayout>
</template>
<script>
import EditDataRepairsLayout from "@/layouts/EditDataRepairsLayout.vue";
import ModalMessage from "@/components/ModalMessage.vue";
import { HTTPGET } from "@/api";
export default {
  name: "EditZapSC",
  props: ["data"],
  components: {
    EditDataRepairsLayout,
    ModalMessage
  },
  computed: {},
  data: () => ({
    visibleAddField: false,
    addField: [],
    visibleMessage: false,
    zzz: []
  }),
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    deleteZapSC(idx) {
      this.zzz.splice(idx, 1);
    },
    add() {
      this.visibleAddField = true;
      this.zzz.push({ name: "", pcs: 1, cena: 1 });
    },
    hideMessage() {
      this.visibleMessage = false;
    },
    save() {
      const elem = document.getElementsByClassName("is-invalid");
      if (elem.length) {
        this.visibleMessage = true;
      } else {
        HTTPGET({
          url: "repairs/repair_edit/edit_zap_sc.php",
          params: {
            id: this.$route.params.id,
            zap: JSON.stringify(this.zzz)
          }
        }).then(() => this.$emit("cancel"));
      }
    }
  },
  mounted() {
    this.zzz = this.data.zap_sc;
  }
};
</script>
<style lang="scss" scoped>
table {
  margin: auto;
  font-size: 0.8em;
}
td {
  padding: 3px;
  vertical-align: middle;
}
.wrapper {
  padding: 15px;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 3px;
  display: table;
  margin: auto;
}
.btn {
  margin-right: 15px;
  margin-top: 10px;
}
.bi-x {
  font-size: 2em;
  cursor: pointer;
  color: red;
}
input {
  width: 90%;
}
.w4 {
  width: 6em;
}
.w10 {
  width: 10em;
}
</style>
