<template>
  <EditDataRepairsLayout>
    <div class="wrapper">
      <table>
        <tbody>
          <tr>
            <td
              colspan="2"
              class="text-center font-weight-bold"
              v-if="data.report_price !== 'неавторизованный'"
            >
              {{ data.report_price }} расценки
            </td>
            <td
              colspan="2"
              class="text-center font-weight-bold text-danger"
              v-else
            >
              {{ data.report_price }}
            </td>
          </tr>
          <tr>
            <td>стоимость работ</td>
            <td>
              <input
                type="number"
                class="form-control form-control-sm input-form shadow-none"
                v-model.number="price"
                ref="inp"
                @keyup.enter="save"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-sm btn-outline-success shadow-none" @click="save">
        сохранить
      </button>
      <button class="btn btn-sm btn-outline-danger shadow-none" @click="cancel">
        отмена
      </button>
    </div>
  </EditDataRepairsLayout>
</template>
<script>
import EditDataRepairsLayout from "@/layouts/EditDataRepairsLayout.vue";
import { HTTPGET } from "@/api";
export default {
  name: "EditPrice",
  props: ["data"],
  components: {
    EditDataRepairsLayout
  },
  data: () => ({
    price: null
  }),
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      if (this.price > 0 || this.price === 0) {
        HTTPGET({
          url: "reports/edit_price.php",
          params: {
            repair_price: this.price,
            id: this.data.remont_id
          }
        }).then(() => this.$emit("cancel"));
      }
    }
  },
  mounted() {
    this.price = !this.data.repair_price
      ? this.data.standart_price
      : this.data.repair_price;
    this.$refs.inp.focus();
  }
};
</script>
<style lang="scss" scoped>
td {
  padding: 3px;
}
.wrapper {
  padding: 15px;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 3px;
  display: table;
  margin: auto;
}
.btn {
  margin-right: 15px;
  margin-top: 10px;
}
</style>
