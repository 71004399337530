<template>
  <EditDataRepairsLayout>
    <div class="wrapper">
      <table>
        <tbody>
          <tr>
            <td>продавец</td>
            <td>
              <input
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                v-model.trim="seller"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-sm btn-outline-success shadow-none" @click="save">
        сохранить
      </button>
      <button class="btn btn-sm btn-outline-danger shadow-none" @click="cancel">
        отмена
      </button>
    </div>
  </EditDataRepairsLayout>
</template>
<script>
import EditDataRepairsLayout from "@/layouts/EditDataRepairsLayout.vue";
import { HTTPGET } from "@/api";
export default {
  name: "EditSeller",
  props: ["data"],
  components: {
    EditDataRepairsLayout
  },
  data: () => ({
    seller: null
  }),
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      if (this.seller === this.data.seller) {
        this.$emit("cancel");
        return;
      }
      if (this.seller === "") {
        alert("это поле не может быть пустым");
        this.seller = "";
      } else {
        HTTPGET({
          url: "repairs/repair_edit/edit_repair_seller.php",
          params: {
            seller: this.seller,
            id: this.data.remont_id
          }
        }).then(() => this.$emit("cancel"));
      }
    }
  },
  mounted() {
    this.seller = this.data.seller;
  }
};
</script>
<style lang="scss" scoped>
td {
  padding: 3px;
}
.wrapper {
  padding: 15px;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 3px;
  display: table;
  margin: auto;
}
.btn {
  margin-right: 15px;
  margin-top: 10px;
}
</style>
