var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"item-photo-wrapper",class:_vm.loadinger ? 'visible' : 'no-visible'},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12 text-center"},[_c('h6',[_vm._v(_vm._s(_vm.title))]),_c('div',[_c('button',{staticClass:"btn btn-sm btn-outline-success shadow-none m-1",on:{"click":function($event){return _vm.addFile({ remont_id: _vm.data.remont_id, type: _vm.type, title: _vm.title })}}},[_vm._v(" добавить файл ")])])])]),_c('div',{staticClass:"form-row foto-row"},_vm._l((_vm.dataPhoto),function(item,idx){return _c('div',{key:idx,staticClass:"form-group col-md-4"},[_c('div',{attrs:{"title":item.name}},[(
              item.name
                .substring(item.name.length - 4, item.name.length)
                .toUpperCase() == '.MP4' ||
                item.name
                  .substring(item.name.length - 4, item.name.length)
                  .toUpperCase() == '.MOV'
            )?_c('div',[_c('p',{staticClass:"text-center"},[_c('a',{attrs:{"href":item.path0 + item.path,"target":"blank"}},[_vm._v(_vm._s(item.name))])]),_c('div',[_c('video',{staticClass:"min-photo",attrs:{"src":_vm.url + item.path,"controls":""}})])]):_c('div',[_c('p',{staticClass:"text-center"},[_c('a',{attrs:{"href":item.path0 + item.path,"target":"blank"}},[_vm._v(_vm._s(item.name))])]),_c('div',[_c('img',{staticClass:"min-photo",attrs:{"src":_vm.url + item.path,"alt":""},on:{"click":function($event){return _vm.viewPhoto(_vm.url + item.path)}}})])]),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-sm btn-outline-danger shadow-none delete m-1",on:{"click":function($event){return _vm.del(_vm.data.remont_id, _vm.data.id_user, item.name)}}},[_vm._v(" удалить ")]),(
                _vm.USER_INFO.status === 'admin' && item.mime_type == 'image/jpeg'
              )?_c('button',{staticClass:"btn btn-sm btn-outline-primary shadow-none m-1",on:{"click":function($event){return _vm.rotate(_vm.data.remont_id, _vm.data.id_user, item.name)}}},[_c('i',{staticClass:"bi bi-arrow-counterclockwise"}),_vm._v(" повернуть ")]):_vm._e()])]),(item.duplicate && _vm.USER_INFO.status === 'admin')?_c('div',{staticClass:"text-center"},_vm._l((item.duplicate),function(item2,idx2){return _c('div',{key:idx2 + 'duplicatef',staticClass:"text-small bg-warning m-1"},[(_vm.data.repair_number !== item2.repair_number)?_c('a',{attrs:{"href":("" + (item2.id)),"target":"_blank"}},[_vm._v("фото использовалось в "+_vm._s(item2.repair_number))]):_vm._e()])}),0):_vm._e()])}),0)]),(_vm.visibleBigFoto)?_c('div',{staticClass:"big-photo"},[_c('div',{staticClass:"big-photo-wrapper"},[_c('img',{attrs:{"width":_vm.bigPhotoWidth + '%',"src":_vm.bigPhotoUrl,"alt":""},on:{"click":_vm.hideBigPhoto}})])]):_c('div'),(_vm.visibleMessage)?_c('ModalMessage',{attrs:{"message":"в каждой категории кроме категории \n    'дополнительные материалы' \n    должно быть минимум одно фото. \n    Сначала добавьте нужное фото \n    потом удалите ненужное"},on:{"hideMessage":_vm.hideMessage}}):_c('div')],1)}
var staticRenderFns = []

export { render, staticRenderFns }