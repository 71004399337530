var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"row"},[(_vm.USER_INFO.status === 'admin')?_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.seti),expression:"data.seti"}],staticClass:"custom-control-input shadow-none",attrs:{"type":"checkbox","id":"customCheck1"},domProps:{"checked":Array.isArray(_vm.data.seti)?_vm._i(_vm.data.seti,null)>-1:(_vm.data.seti)},on:{"change":[function($event){var $$a=_vm.data.seti,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.data, "seti", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.data, "seti", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.data, "seti", $$c)}},_vm.seti]}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"customCheck1"}},[_vm._v("сетевой магазин")])]):_vm._e(),(_vm.repairs && _vm.repairs.length)?_c('div',{staticClass:"prev-next"},[_c('button',{staticClass:"btn btn-sm btn-outline-primary shadow-none mr-1",on:{"click":function($event){return _vm.nextOrPrevRepair('prev')}}},[_c('i',{staticClass:"bi bi-arrow-left"}),_vm._v(" предыдущий ")]),_c('button',{staticClass:"btn btn-sm btn-outline-primary shadow-none mr-1",attrs:{"disabled":""}},[_vm._v(" "+_vm._s(_vm.number_repair)+" ")]),_c('button',{staticClass:"btn btn-sm btn-outline-primary shadow-none mr-1",on:{"click":function($event){return _vm.nextOrPrevRepair('next')}}},[_vm._v(" следующий "),_c('i',{staticClass:"bi bi-arrow-right"})])]):_vm._e(),_c('div',{staticClass:"prev-next"},[(
          _vm.data.typeOfRepair === 'noRepair' &&
            !_vm.data.confirm_act_guid &&
            _vm.USER_INFO.status === 'admin'
        )?_c('button',{staticClass:"btn btn-sm btn-outline-success shadow-none ml-2",on:{"click":_vm.confirmAct}},[_vm._v(" подтвердить акт ")]):_c('span'),(_vm.data.typeOfRepair === 'noRepair' && _vm.data.confirm_act_guid)?_c('button',{staticClass:"btn btn-sm btn-outline-success shadow-none ml-2",on:{"click":_vm.print_act}},[_vm._v(" печать акта ")]):_vm._e(),(
          _vm.data.typeOfRepair === 'noRepair' &&
            _vm.data.confirm_act_guid &&
            !_vm.data.repair_is_over_data &&
            _vm.USER_INFO.status === 'admin'
        )?_c('button',{staticClass:"btn btn-sm btn-outline-danger shadow-none ml-2",on:{"click":_vm.cancel_confirm_act}},[_vm._v(" отменить акт ")]):_vm._e()]),_c('div',{staticClass:"prev-next"},[(
          _vm.data.typeOfRepair === 'diagnostiks' &&
            !_vm.data.repair_confirm &&
            _vm.USER_INFO.status === 'admin'
        )?_c('button',{staticClass:"btn btn-sm btn-outline-warning shadow-none ml-2",on:{"click":_vm.confirmDiagnostiks}},[_vm._v(" подтвердить ремонт ")]):_vm._e(),(
          _vm.data.typeOfRepair === 'diagnostiks' &&
            _vm.data.repair_confirm &&
            !_vm.data.repair_is_over_data &&
            _vm.USER_INFO.status === 'admin'
        )?_c('button',{staticClass:"btn btn-sm btn-outline-primary shadow-none ml-2",on:{"click":_vm.cancelConfirmDiagnostiks}},[_vm._v(" отменить подтверждение диагностики ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }