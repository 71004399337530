<template>
  <div class="wrapper">
    <div class="row">
      <div
        class="custom-control custom-checkbox"
        v-if="USER_INFO.status === 'admin'"
      >
        <input
          type="checkbox"
          class="custom-control-input shadow-none"
          id="customCheck1"
          v-model="data.seti"
          @change="seti"
        />
        <label class="custom-control-label" for="customCheck1"
          >сетевой магазин</label
        >
      </div>
      <div v-if="repairs && repairs.length" class="prev-next">
        <button
          class="btn btn-sm btn-outline-primary shadow-none mr-1"
          @click="nextOrPrevRepair('prev')"
        >
          <i class="bi bi-arrow-left"></i>
          предыдущий
        </button>
        <button
          class="btn btn-sm btn-outline-primary shadow-none mr-1"
          disabled
        >
          {{ number_repair }}
        </button>
        <button
          class="btn btn-sm btn-outline-primary shadow-none mr-1"
          @click="nextOrPrevRepair('next')"
        >
          следующий
          <i class="bi bi-arrow-right"></i>
        </button>
      </div>
      <div class="prev-next">
        <button
          v-if="
            data.typeOfRepair === 'noRepair' &&
              !data.confirm_act_guid &&
              USER_INFO.status === 'admin'
          "
          class="btn btn-sm btn-outline-success shadow-none ml-2"
          @click="confirmAct"
        >
          подтвердить акт
        </button>
        <span v-else />
        <button
          v-if="data.typeOfRepair === 'noRepair' && data.confirm_act_guid"
          class="btn btn-sm btn-outline-success shadow-none ml-2"
          @click="print_act"
        >
          печать акта
        </button>
        <button
          v-if="
            data.typeOfRepair === 'noRepair' &&
              data.confirm_act_guid &&
              !data.repair_is_over_data &&
              USER_INFO.status === 'admin'
          "
          class="btn btn-sm btn-outline-danger shadow-none ml-2"
          @click="cancel_confirm_act"
        >
          отменить акт
        </button>
      </div>
      <div class="prev-next">
        <button
          v-if="
            data.typeOfRepair === 'diagnostiks' &&
              !data.repair_confirm &&
              USER_INFO.status === 'admin'
          "
          class="btn btn-sm btn-outline-warning shadow-none ml-2"
          @click="confirmDiagnostiks"
        >
          подтвердить ремонт
        </button>
        <button
          v-if="
            data.typeOfRepair === 'diagnostiks' &&
              data.repair_confirm &&
              !data.repair_is_over_data &&
              USER_INFO.status === 'admin'
          "
          class="btn btn-sm btn-outline-primary shadow-none ml-2"
          @click="cancelConfirmDiagnostiks"
        >
          отменить подтверждение диагностики
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { HTTPGET } from "@/api";
import u from "@/constants/";
export default {
  name: "OneRepairActions",
  props: ["data"],
  data: () => ({
    id: null,
    href: "",
    repairs_list: null,
    repairs: []
  }),
  computed: {
    ...mapGetters(["USER_INFO", "REPAIRSLISTALLFILTER", "REPAIRSLISTALLLIMIT"]),
    number_repair: function() {
      return `${this.repairs.findIndex(i => i == this.$route.params.id) +
        1} из ${this.repairs.length}`;
    }
  },
  watch: {
    data: function() {
      this.get_list_repairs_in_order();
    },
    repairs_list: function() {
      if (this.repairs_list) {
        this.repairs = [];
        this.repairs_list.map(item => this.repairs.push(item.remont_id));
      }
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.href = `${u.url1}acts/pdf/?id=`;
    console.log(this.data.repair_is_over);
  },
  methods: {
    confirmAct() {
      confirm("точно подтвердить?")
        ? HTTPGET({
            url: "acts/confirm_act.php",
            params: {
              id: this.id
            }
          }).then(() => this.$router.push("/repairs/acts/acts-list"))
        : null;
    },
    seti() {
      HTTPGET({
        url: "/repairs/repair_edit/set_seti.php",
        params: {
          id: this.$route.params.id,
          seti: this.data.seti
        }
      });
    },
    confirmDiagnostiks() {
      HTTPGET({
        url: "/repairs/confirm_diagnostiks.php",
        params: {
          id: this.$route.params.id
        }
      }).then(() => this.$emit("update"));
    },
    cancelConfirmDiagnostiks() {
      HTTPGET({
        url: "/repairs/cancel_confirm_diagnostiks.php",
        params: {
          id: this.$route.params.id
        }
      }).then(() => this.$emit("update"));
    },
    nextOrPrevRepair(prevOrNext) {
      const pos = this.repairs.findIndex(i => i == this.$route.params.id);
      const length = this.repairs.length;
      if (prevOrNext === "next" && pos + 1 < length) {
        this.$router.push(`/repairs/repair/${this.repairs[pos + 1]}`);
      }
      if (prevOrNext === "prev" && pos !== 0) {
        this.$router.push(`/repairs/repair/${this.repairs[pos - 1]}`);
      }
    },
    get_list_repairs_in_order() {
      if (this.repairs_list) {
        return;
      }
      let url;
      let id;
      let data = null;
      let limit = null;
      url = "/repairs/get_one_repair.php";
      if (this.$route.query.from === "order") {
        url = "/reports/get_one_report.php";
        id = this.$route.query.order_id;
      }
      if (this.$route.query.from === "repairs_order") {
        url = "repairs/repairs_orders/get_one_order.php";
        id = this.$route.query.order_id;
      }
      if (this.$route.query.from === "new_repairs") {
        url = "repairs/repairs_list.php";
        id = this.$route.query.user_id;
      }
      if (this.$route.query.from === "all_repairs") {
        url = "repairs/repairs_list_all.php?limit=all";
        id = this.$route.query.user_id;

        const filter = this.REPAIRSLISTALLFILTER.filter(
          item => item.value.length > 0
        );
        data = JSON.stringify(filter);
        limit = this.REPAIRSLISTALLLIMIT;
      }
      HTTPGET({
        url: url,
        params: {
          id: id,
          filter: data,
          limit: limit
        }
      }).then(response => (this.repairs_list = response.data.repairs));
    },
    print_act() {
      window.open(this.href + this.data.confirm_act_guid);
    },
    cancel_confirm_act() {
      if (confirm("отменить?")) {
        HTTPGET({
          url: "acts/cancel_confirm_act.php",
          params: {
            id: this.data.id
          }
        }).then(() => this.$emit("update"));
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: inline-block;
}
.custom-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-left: 15px;
}
.custom-control-label {
  cursor: pointer;
}
.prev-next {
  margin: 10px;
}
</style>
