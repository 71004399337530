<template>
  <EditDataRepairsLayout>
    <div class="wrapper col-md-6">
      <table class="table table-sm col-md-12" v-if="zapList">
        <thead>
          <tr>
            <th colspan="4">
              <SelectZapForRepair
                :dataZap="LIST_ZAP"
                :zapForRepair="zapList"
                :downloadPercentage="downloadPercentage"
                :disabled="dataZap.loading"
                @addZapForRepair="addZapForRepair"
                :oldzapvisible="true"
                :newZapTitle="`заказать запчасти для этого ремонта`"
                :cenaVisible="false"
                :id_user="data.dat.id_user"
              />
            </th>
          </tr>
        </thead>
      </table>
      <button class="btn btn-sm btn-outline-success shadow-none" @click="save">
        сохранить
      </button>
      <button class="btn btn-sm btn-outline-danger shadow-none" @click="cancel">
        отмена
      </button>
      <button
        v-if="USER_INFO.status === 'admin'"
        class="btn btn-sm btn-outline-primary shadow-none"
        @click="addNewPosInZap"
      >
        создать отсутствующую позицию
      </button>
      <span v-else />
      <div v-if="USER_INFO.status === 'admin' && newPosVisible">
        <table class="table table-sm">
          <tr>
            <td colspan="2">
              <input
                type="text"
                v-model.trim="newPosCod"
                placeholder="код"
                class="form-control form-control-sm input-form shadow-none newposbtn"
                :class="newPosCod.length > 0 ? '' : 'is-invalid'"
              />
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <input
                type="text"
                v-model.trim="newPosArt"
                placeholder="артикул"
                class="form-control form-control-sm input-form shadow-none newposbtn"
                :class="newPosArt.length > 0 ? '' : 'is-invalid'"
              />
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <input
                type="text"
                v-model.trim="newPosName"
                placeholder="наименование"
                class="form-control form-control-sm input-form shadow-none newposbtn"
                :class="newPosName.length > 0 ? '' : 'is-invalid'"
              />
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <input
                type="number"
                min="1"
                v-model.number="newPosPcs"
                placeholder="наименование"
                class="form-control form-control-sm input-form shadow-none newposbtn"
              />
            </td>
          </tr>
          <tr>
            <td>
              <button
                class="btn btn-sm btn-outline-success shadow-none newposbtn"
                @click="saveNewPos"
              >
                создать
              </button>
              <button
                class="btn btn-sm btn-outline-danger shadow-none newposbtn"
                @click="cancelNewPos"
              >
                отмена
              </button>
            </td>
            <td></td>
          </tr>
        </table>
      </div>
      <div v-else />
      <div>
        <a href="" @click.prevent="getSheme">показать схему</a>
      </div>
      <div v-if="visibleSheme" class="col-md-12">
        <div v-if="!no_photo_sheme && shemeFotoUrl" class="sheme-wrapper">
          <div class="sheme" v-for="url in shemeFotoUrl.name" :key="url.id">
            <h6 class="text-center">
              {{ url.name }}
              <a href="#" @click.prevent="getVisibleSheme(url.name)"
                >показать/скрыть - схему</a
              >
            </h6>
            <div v-if="visibleShemeImage === url.name">
              <div><a :href="url.download_url" target="blank">скачать</a></div>
              <img
                v-for="i in shemeFotoUrl[url.name]"
                :key="i.id"
                :src="i.url"
              />
            </div>
          </div>
        </div>
        <div v-else class="text-center card" style="width: 50%;margin:auto;">
          <div class="card-body">
            <p>не удалось найти схему</p>
            <p>
              попробуйте поискать
              <a
                href="https://okzip.ru/index.php?resources/categories/СХЕМЫ-КАЛИБР.2/"
                target="blank"
                >тут</a
              >
            </p>
            <p>или обратитесь к своему менеджеру</p>
          </div>
        </div>
      </div>
    </div>
  </EditDataRepairsLayout>
</template>
<script>
import EditDataRepairsLayout from "@/layouts/EditDataRepairsLayout.vue";
import SelectZapForRepair from "@/components/SelectZapForRepair.vue";
import { HTTPGET } from "@/api";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "EditZap",
  props: ["data"],
  watch: {
    data: function(newVal) {
      this.data = newVal;
      this.zap(this.data.zap);
    }
  },
  components: {
    EditDataRepairsLayout,
    SelectZapForRepair
  },
  data: () => ({
    downloadPercentage: "",
    dataZap: {
      data: null,
      loading: false,
      placeholder: ""
    },
    zapList: [],
    shemeFotoUrl: null,
    no_photo_sheme: false,
    visibleSheme: false,
    visibleShemeImage: false,
    oldUserZapOrder: [],
    newPosVisible: false,
    newPosArt: "",
    newPosCod: "",
    newPosName: "",
    newPosPcs: 1
  }),
  computed: {
    ...mapGetters([
      "LIST_ZAP",
      "PLACEHOLDER",
      "DOWNLOADPERCENTAGE",
      "ZAPLOADING",
      "USERZAPORDER",
      "OLDUSERZAPORDER",
      "ZAP",
      "USER_INFO"
    ])
  },
  methods: {
    ...mapMutations([
      "setUserZapOrder",
      "oldZap",
      "zap",
      "zapNull",
      "userZapOrderNulll",
      "userZapListNull"
    ]),
    getVisibleSheme(name) {
      if (this.visibleShemeImage === name) {
        this.visibleShemeImage = false;
      } else {
        this.visibleShemeImage = name;
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    async save() {
      let zzz = this.ZAP;
      zzz.map(item => (item.name = item.name.substr(0, 50)));
      HTTPGET({
        url: "repairs/repair_edit/edit_repair_zap.php",
        params: {
          id: this.data.dat.remont_id,
          zap: JSON.stringify(zzz),
          user_zap_order: JSON.stringify(this.USERZAPORDER),
          old_user_zap_order: JSON.stringify(this.oldUserZapOrder)
        }
      }).then(() => this.$emit("cancel"));
    },
    addZapForRepair(zap) {
      this.zapList.push(zap);
    },
    deleteZap(idx) {
      this.zapList.splice(idx, 1);
    },
    minValue() {
      this.zapList.map(item => {
        if (item.pcs < 1) {
          item.pcs = 1;
        }
      });
    },
    getSheme() {
      this.visibleSheme = !this.visibleSheme;
      if (this.visibleSheme) {
        HTTPGET({
          url: "get_foto_sheme/",
          params: { cod: this.data.dat.cod }
        })
          .then(response => (this.shemeFotoUrl = response.data))
          .then(response =>
            response
              ? (this.no_photo_sheme = false)
              : (this.no_photo_sheme = true)
          );
      }
    },
    addNewPosInZap() {
      this.newPosVisible = true;
    },
    saveNewPos() {
      if (
        this.newPosCod.length &&
        this.newPosName.length &&
        this.newPosArt.length
      ) {
        HTTPGET({
          url: "repairs/repair_edit/add_new_zap.php",
          params: {
            cod: this.newPosCod,
            art: this.newPosArt,
            name: this.newPosName,
            pcs: this.newPosPcs,
            id: this.data.dat.remont_id
          }
        })
          .then(() => this.cancelNewPos())
          .then(() => this.$emit("updateData"));
      }
    },
    cancelNewPos() {
      this.newPosVisible = false;
      this.newPosCod = "";
      this.newPosName = "";
      this.newPosArt = "";
      this.newPosPcs = 1;
    }
  },
  mounted() {
    this.zap(this.data.zap);
    this.dataZap.data = this.LIST_ZAP;
    this.downloadPercentage = this.DOWNLOADPERCENTAGE;
    this.dataZap.loading = this.ZAPLOADING;
    this.dataZap.placeholder = this.PLACEHOLDER;
    if (this.data.zap) {
      this.zapList = this.data.zap;
    }
    if (this.data.user_zap) {
      this.setUserZapOrder(this.data.user_zap);
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 15px;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 3px;
  display: table;
  margin: auto;
}
.btn {
  margin-right: 15px;
  margin-top: 10px;
}
.bi-x {
  font-size: 2em;
  cursor: pointer;
  color: red;
}
td > input {
  width: 4em;
  border: 1px solid #dee2e6;
  text-align: center;
}
.sheme {
  width: 100%;
}
.sheme-wrapper {
  height: 80vh;
  overflow: auto;
}
.newposbtn {
  margin: 0;
  margin-right: 10px;
}
input.newposbtn {
  width: 95%;
}
</style>
