<template>
  <div class="wrapper">
    <div class="custom-control custom-switch">
      <input
        type="checkbox"
        class="custom-control-input"
        id="customSwitch1"
        v-model="allowEditing"
        @change="getAllowEditing"
      />
      <label class="custom-control-label" for="customSwitch1"
        >разрешить редактирование</label
      >
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "AllowEditingRepair",
  props: ["edit"],
  data: () => ({
    allowEditing: false
  }),
  methods: {
    getAllowEditing() {
      HTTPGET({
        url: "/repairs/repair_edit/allow_edit_repair.php",
        params: {
          id: this.$route.params.id,
          editing: this.allowEditing
        }
      }).then(() => this.$emit("update"));
    }
  },
  mounted() {
    this.edit === "1"
      ? (this.allowEditing = false)
      : (this.allowEditing = true);
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: inline-block;
  margin-left: 10px;
}
label {
  cursor: pointer;
}
</style>
