<template>
  <EditDataRepairsLayout>
    <div class="wrapper">
      <table>
        <tbody>
          <tr>
            <td>категория ремонта</td>
            <td>
              <select
                v-model="repairCategory"
                class="form-control form-control-sm shadow-none"
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-sm btn-outline-success shadow-none" @click="save">
        сохранить
      </button>
      <button class="btn btn-sm btn-outline-danger shadow-none" @click="cancel">
        отмена
      </button>
    </div>
  </EditDataRepairsLayout>
</template>
<script>
import EditDataRepairsLayout from "@/layouts/EditDataRepairsLayout.vue";
import { HTTPGET } from "@/api";
import { mapGetters } from "vuex";
export default {
  name: "EditRepairCategory",
  props: ["data"],
  components: {
    EditDataRepairsLayout
  },
  computed: {
    ...mapGetters(["USER_INFO"])
  },
  data: () => ({
    repairCategory: "",
    url: ""
  }),
  mounted() {
    this.repairCategory = this.data.repair_category;
  },
  methods: {
    save() {
      if (
        this.USER_INFO.gruppa === "super" ||
        this.USER_INFO.gruppa === "otchet"
      ) {
        this.url = "reports/edit_repair_category.php";
      } else {
        this.url = "repairs/repair_edit/edit_repair_category.php";
      }
      HTTPGET({
        url: this.url,
        params: {
          repair_category: this.repairCategory,
          id: this.data.remont_id
        }
      }).then(() => this.$emit("cancel"));
    },
    cancel() {
      this.$emit("cancel");
    }
  }
};
</script>
<style lang="scss" scoped>
td {
  padding: 3px;
}
.wrapper {
  padding: 15px;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 3px;
  display: table;
  margin: auto;
}
.btn {
  margin-right: 15px;
  margin-top: 10px;
}
</style>
