<template>
  <div class="d-inline">
    <div class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input shadow-none"
        id="customCheckConsultation"
        v-model="data.consultation"
        @change="set"
      />
      <label class="custom-control-label" for="customCheckConsultation"
        >нужна консультация мастерской</label
      >
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "AddConsultationRepair",
  props: ["data"],
  data: () => ({}),
  methods: {
    set() {
      HTTPGET({
        url: "repairs/add_consultation.php",
        params: {
          consultation: this.data.consultation,
          id: this.data.id
        }
      }).then(() => this.$emit("update"));
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-checkbox {
  display: inline-block;
  margin: 15px;
}
label {
  cursor: pointer;
}
</style>
