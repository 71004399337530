<template>
  <div v-if="order_id">
    <router-link :to="`/repairs/order/${order_id}?path=''`"
      >перейти в заявку</router-link
    >
  </div>
</template>
<script>
export default {
  name: "RepairOrderNumber",
  props: ["order_id"]
};
</script>
