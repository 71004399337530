<template>
  <div class="edit">
    <div class="wrapper col-md-6">
      <div class="col-md-12 foto">
        <UploadFile
          :title="`${title} кликните для выбора файла`"
          setId="viewFoto"
          :typeFile="typeFileFoto"
          @updateFormDataFoto="setUploadFile"
        />
        <button
          class="btn btn-sm btn-outline-success shadow-none"
          @click="upload"
          :disabled="saveButtonDisabled"
        >
          сохранить
        </button>
        <button
          class="btn btn-sm btn-outline-danger shadow-none"
          @click="cancel"
        >
          отмена
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import UploadFile from "@/views/repairs/UploadFile.vue";
import { HTTPPOSTFILE } from "@/api";
export default {
  name: "AddFileForRepair",
  props: ["typeFileFoto", "title", "remont_id", "type"],
  components: {
    UploadFile
  },
  data: () => ({
    file: [],
    saveButtonDisabled: true
  }),
  methods: {
    setUploadFile(file) {
      this.file = file;
      if (this.file.length) {
        this.saveButtonDisabled = false;
      } else {
        this.saveButtonDisabled = true;
      }
    },
    upload() {
      if (this.file.length > 0) {
        let formData = new FormData();
        for (let i = 0; i < this.file.length; i++) {
          let file = this.file[i];
          formData.append(`${this.type}[${i}]`, file);
        }
        let data = { remont_id: this.remont_id, type: this.type };
        data = JSON.stringify(data);
        formData.append("data", data);
        HTTPPOSTFILE({
          url: "repairs/repair_edit/edit_repair_add_file.php",
          data: formData,
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        }).then(() => this.cancel());
      }
    },
    cancel() {
      this.$emit("addFileClose");
      this.file = [];
    }
  }
};
</script>
<style lang="scss" scoped>
.edit {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #ffffff, $alpha: 0.8);
  z-index: 10;
  align-items: center;
}
.btn {
  margin: 10px;
}
.foto {
  margin: auto;
}
.wrapper {
  padding: 15px;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin: 100px auto;
}
.image-preview {
  margin-top: 10px;
}
</style>
