<template>
  <div>
    <EditDataRepairsLayout>
      <div class="wrapper">
        <table>
          <tbody>
            <tr>
              <td>дата выдачи</td>
              <td>
                <DatePicker
                  v-model="data.repair_is_over_data2"
                  valueType="YYYY-MM-DD"
                ></DatePicker>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <button
            class="btn btn-sm btn-outline-success shadow-none"
            @click="save"
          >
            сохранить
          </button>
          <button
            class="btn btn-sm btn-outline-danger shadow-none"
            @click="cancel"
          >
            отмена
          </button>
        </div>
      </div>
    </EditDataRepairsLayout>
  </div>
</template>
<script>
import EditDataRepairsLayout from "@/layouts/EditDataRepairsLayout.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";
import { HTTPGET } from "@/api";
export default {
  name: "EditRepairIsOverData",
  props: ["data"],
  components: {
    EditDataRepairsLayout,
    DatePicker
  },
  data: () => ({
    dateRepair: null
  }),
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      HTTPGET({
        url: "repairs/repair_edit/edit_repair_is_over_data.php",
        params: {
          date: this.data.repair_is_over_data2,
          id: this.data.remont_id
        }
      }).then(() => this.$emit("cancel"));
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 15px;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 3px;
  display: table;
  margin: auto;
}
.btn {
  margin-right: 15px;
  margin-top: 10px;
}
</style>
