<template>
  <div class="d-inline">
    <div
      class="custom-control custom-checkbox"
      v-if="USER_INFO.status === 'admin'"
    >
      <input
        type="checkbox"
        class="custom-control-input shadow-none"
        id="customCheck11"
        v-model="data.guarantee"
        @change="set"
      />
      <label class="custom-control-label" for="customCheck11"
        >не гарантия</label
      >
    </div>
    <div
      v-if="USER_INFO.status !== 'admin' && data.guarantee"
      class="custom-control custom-checkbox"
    >
      <span class="badge badge-danger">не гарантия</span>
    </div>
    <div
      class="custom-control custom-checkbox"
      v-if="USER_INFO.status === 'admin'"
    >
      <input
        type="checkbox"
        class="custom-control-input shadow-none"
        id="customCheck111"
        v-model="data.no_defect"
        @change="set_no_defect"
      />
      <label class="custom-control-label" for="customCheck111"
        >нет дефекта</label
      >
    </div>
    <div
      v-if="USER_INFO.status !== 'admin' && data.no_defect"
      class="custom-control custom-checkbox"
    >
      <span class="badge badge-danger">нет дефекта</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { HTTPGET } from "@/api";
export default {
  name: "NoGuarantee",
  props: ["data"],
  computed: {
    ...mapGetters(["USER_INFO"])
  },
  data: () => ({}),
  methods: {
    set() {
      HTTPGET({
        url: "repairs/set_guarantee.php",
        params: {
          guarantee: this.data.guarantee,
          id: this.data.id
        }
      }).then(() => this.$emit("update"));
    },
    set_no_defect() {
      HTTPGET({
        url: "repairs/set_no_defect.php",
        params: {
          no_defect: this.data.no_defect,
          id: this.data.id
        }
      }).then(() => this.$emit("update"));
    }
  }
};
</script>
<style lang="scss" scoped>
.custom-checkbox {
  display: inline-block;
  margin: 15px;
}
label {
  cursor: pointer;
}
</style>
