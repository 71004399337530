<template>
  <div>
    <div
      class="item-photo-wrapper"
      :class="loadinger ? 'visible' : 'no-visible'"
    >
      <div class="form-row">
        <div class="form-group col-md-12 text-center">
          <h6>{{ title }}</h6>
          <div>
            <button
              class="btn btn-sm btn-outline-success shadow-none m-1"
              @click="
                addFile({ remont_id: data.remont_id, type: type, title: title })
              "
            >
              добавить файл
            </button>
          </div>
        </div>
      </div>
      <div class="form-row foto-row">
        <div
          v-for="(item, idx) in dataPhoto"
          :key="idx"
          class="form-group col-md-4"
        >
          <div :title="item.name">
            <div
              v-if="
                item.name
                  .substring(item.name.length - 4, item.name.length)
                  .toUpperCase() == '.MP4' ||
                  item.name
                    .substring(item.name.length - 4, item.name.length)
                    .toUpperCase() == '.MOV'
              "
            >
              <p class="text-center">
                <a :href="item.path0 + item.path" target="blank">{{
                  item.name
                }}</a>
              </p>
              <div>
                <video
                  class="min-photo"
                  :src="url + item.path"
                  controls
                ></video>
              </div>
            </div>
            <div v-else>
              <p class="text-center">
                <a :href="item.path0 + item.path" target="blank">{{
                  item.name
                }}</a>
              </p>
              <div>
                <img
                  class="min-photo"
                  :src="url + item.path"
                  alt=""
                  @click="viewPhoto(url + item.path)"
                />
              </div>
            </div>
            <div class="text-center">
              <button
                class="btn btn-sm btn-outline-danger shadow-none delete m-1"
                @click="del(data.remont_id, data.id_user, item.name)"
              >
                удалить
              </button>
              <button
                class="btn btn-sm btn-outline-primary shadow-none m-1"
                @click="rotate(data.remont_id, data.id_user, item.name)"
                v-if="
                  USER_INFO.status === 'admin' && item.mime_type == 'image/jpeg'
                "
              >
                <i class="bi bi-arrow-counterclockwise"></i>
                повернуть
              </button>
            </div>
          </div>
          <div
            v-if="item.duplicate && USER_INFO.status === 'admin'"
            class="text-center"
          >
            <div
              v-for="(item2, idx2) in item.duplicate"
              :key="idx2 + 'duplicatef'"
              class="text-small bg-warning m-1"
            >
              <a
                v-if="data.repair_number !== item2.repair_number"
                :href="`${item2.id}`"
                target="_blank"
                >фото использовалось в {{ item2.repair_number }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="big-photo" v-if="visibleBigFoto">
      <div class="big-photo-wrapper">
        <img
          :width="bigPhotoWidth + '%'"
          :src="bigPhotoUrl"
          alt=""
          @click="hideBigPhoto"
        />
      </div>
    </div>
    <div v-else />
    <ModalMessage
      v-if="visibleMessage"
      @hideMessage="hideMessage"
      message="в каждой категории кроме категории 
      'дополнительные материалы' 
      должно быть минимум одно фото. 
      Сначала добавьте нужное фото 
      потом удалите ненужное"
    />
    <div v-else />
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import u from "@/constants/";
import ModalMessage from "@/components/ModalMessage.vue";
import { mapGetters } from "vuex";

export default {
  name: "PhotoWrapper",
  props: ["data", "dataPhoto", "title", "type"],
  components: {
    ModalMessage
  },
  computed: {
    ...mapGetters(["USER_INFO"])
  },
  data: () => ({
    user: null,
    visibleBigFoto: false,
    bigPhotoUrl: null,
    visibleMessage: false,
    bigPhotoWidth: 100,
    url: "",
    loadinger: true
  }),
  methods: {
    del(id, user, name) {
      if (
        this.type !== "dataAdditionalFoto" &&
        this.USER_INFO.status !== "admin"
      ) {
        if (this.dataPhoto.length < 2) {
          this.visibleMessage = true;
          return;
        }
      }
      if (confirm(`точно удалить ${name}`)) {
        HTTPGET({
          url: "repairs/repair_edit/delete_photo.php",
          params: {
            id,
            user,
            name,
            type: this.type
          }
        }).then(() => this.$emit("update"));
      }
    },
    rotate(id, user, name) {
      HTTPGET({
        url: "repairs/repair_edit/rotate_photo.php",
        params: {
          id,
          user,
          name,
          type: this.type
        }
      }).then(() => this.$emit("update"));
    },
    addFile(data) {
      this.$emit("addFile", data);
    },
    hideMessage() {
      this.visibleMessage = false;
    },
    viewPhoto(url) {
      this.visibleBigFoto = true;
      this.bigPhotoUrl = url;
    },
    hideBigPhoto() {
      this.visibleBigFoto = false;
      this.bigPhotoUrl = null;
    },
    fff() {
      this.loadinger = true;
    }
  },
  mounted() {
    this.url = u.url;
  }
};
</script>
<style lang="scss" scoped>
.min-photo {
  width: 95%;
}
.foto-row {
  justify-content: center;
}
.item-photo-wrapper {
  border: 1px solid #dee2e6;
  margin-bottom: 3px;
}
p {
  overflow: hidden;
}
.btn,
.text-small {
  font-size: 0.7em;
}
.big-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  background: black;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

img {
  cursor: pointer;
}
.delete {
  margin-top: 10px;
}
.big-photo-wrapper {
  height: 95%;
  width: 95%;
}
.b-photo {
  height: 95%;
}
.visible {
  display: block;
}
.no-visible {
  display: none;
}
</style>
