<template>
  <div class="card">
    <div class="card-header text-center">
      {{ title }}
    </div>
    <div class="card-body" id="messages">
      <div
        class="message alert alert-secondary"
        v-for="(item, idx) in messages"
        :class="item.status === 'admin' ? 'text-right ml' : 'mr'"
        :key="idx"
      >
        <span class="author">
          {{ item.user + " " + item.data }}
        </span>
        <i
          v-if="USER_INFO.status === 'admin'"
          :class="item.status === 'admin' ? ' c-del' : 'b-del'"
          title="удалить сообщение"
          @click="delMessage(item.id)"
          class="bi bi-x"
        ></i>
        <div v-else />
        <div
          class="message-text"
          v-html="item.message.replaceAll('\n', '<br>')"
        ></div>
        <span class="message-status">
          {{ item.data_read ? `прочитано ${item.data_read}` : "не прочитано" }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-9">
        <textarea
          class="form-control form-control-sm input-form shadow-none"
          placeholder="введите сообщение"
          v-model.trim="mesageText"
          @click="update"
        ></textarea>
      </div>
      <div class="col-md-3 btn-send-wrapper">
        <button
          class="btn btn-sm btn-outline-success shadow-none"
          @click="send"
          :disabled="disabled_button_send"
        >
          отправить
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Chat",
  props: ["urlSendMessage", "urlDeleteMessage", "chatId", "title"],
  data: () => ({
    messages: null,
    mesageText: null,
    readMessage: false,
    interval: null,
    disabled_button_send: false
  }),
  computed: {
    ...mapGetters(["USER_INFO", "RELOADCHAT"])
  },
  mounted() {
    this.getMessages();
    this.reload_chat();
  },
  methods: {
    ...mapMutations(["get_reload_cahat"]),
    send() {
      if (this.mesageText && this.mesageText) {
        this.disabled_button_send = true;
        HTTPGET({
          url: this.urlSendMessage,
          params: {
            id: this.$route.params.id,
            message: this.mesageText
          }
        })
          .then(() => this.getMessages())
          .then(() => (this.mesageText = null));
      }
    },
    getMessages() {
      HTTPGET({
        url: this.urlSendMessage,
        params: {
          read_message: this.$route.params.id
        }
      }).then(() => {
        HTTPGET({
          url: this.urlSendMessage,
          params: {
            id: this.$route.params.id
          }
        })
          .then(response => (this.messages = response.data))
          .then(() => {
            const block = document.getElementById("messages");
            block.scrollTop = block.scrollHeight;
          })
          .then(() => this.get_reload_cahat(false))
          .then(() => (this.disabled_button_send = false));
      });
    },
    reload_chat() {
      this.interval = setInterval(() => {
        if (this.RELOADCHAT) {
          this.getMessages();
        }
      }, 1000);
    },
    delMessage(id) {
      HTTPGET({
        url: this.urlDeleteMessage,
        params: {
          delid: id
        }
      }).then(() => this.getMessages());
    },
    update() {
      this.getMessages();
    },
    html(str) {
      return str.replaceAll("\n", "<br>");
    }
  },
  destroyed() {
    clearInterval(this.interval);
  }
};
</script>
<style lang="scss" scoped>
textarea {
  margin: 3px;
  width: 95%;
}
textarea:focus {
  border: 1px solid #ced4da;
}
.btn-send-wrapper {
  display: flex;
  align-items: center;
}
.card-body {
  max-height: 300px;
  overflow: auto;
}
.author {
  padding: 0;
  font-size: 0.7em;
  border-bottom: 1px solid #8c9297;
}
.message-status {
  padding: 0;
  font-size: 0.7em;
  border-top: 1px solid #8c9297;
}
.message {
  position: relative;
  background: #ffffff;
}
.b-del {
  right: 0;
}
.c-del {
  left: 0;
}
.bi-x {
  position: absolute;
  top: 0;
  font-size: 1.3em;
  color: #ff0000;
  cursor: pointer;
}
.bi-x:hover {
  background: #ff0000;
  color: #ffffff;
}
.ml {
  margin-left: 25%;
}
.mr {
  margin-right: 25%;
}
</style>
