<template>
  <table class="table table-sm" @click="editZap">
    <thead>
      <tr>
        <th :colspan="customField ? 2 : 5" class="text-center">{{ title }}</th>
        <th class="text-right">
          <i class="bi bi-pencil-square" @click="editZap"></i>
        </th>
      </tr>
      <tr>
        <th class="text-center">наименование</th>
        <th v-if="!customField" class="text-center">артикул</th>
        <th v-if="!customField" class="text-center">код</th>
        <th class="text-center">шт</th>
        <th v-if="ostatok" class="text-center">остаток</th>
        <th class="text-center">цена</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, idx) in data" :key="idx">
        <td :title="item.name">
          <div>
            {{
              item.name.length > 55
                ? `${item.name.substr(0, 55)}...`
                : item.name
            }}
          </div>
          <div v-if="item.nomer_nakl" class="number_nakl">
            {{ `${item.nomer_nakl} от ${item.data_nakl_format}` }}
          </div>
        </td>
        <td v-if="!customField" class="text-center" :title="item.art">
          {{ item.art.length > 7 ? `${item.art.substr(0, 7)}...` : item.art }}
        </td>
        <td v-if="!customField" class="text-center">{{ item.cod }}</td>
        <td class="text-center">{{ item.pcs }}</td>
        <td
          v-if="ostatok"
          class="text-center"
          :class="!item.ostatok && 'text-danger font-weight-bold'"
        >
          {{ item.ostatok ? item.ostatok : 0 }}
        </td>
        <td class="text-center">{{ price ? item.price : item.cena }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "ZapTable",
  props: [
    "data",
    "title",
    "customField",
    "ostatok",
    "price",
    "cena",
    "number_nakl"
  ],
  methods: {
    editZap() {
      this.$emit("editZap");
    }
  }
};
</script>
<style scoped>
.number_nakl {
  color: #007bff;
  font-size: 0.8em;
}
</style>
