<template>
  <div class="edit">
    <div class="edit-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "EditDataRepairsLayout"
};
</script>
<style lang="scss" scoped>
.edit {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #ffffff, $alpha: 0.8);
  z-index: 10;
}
.edit-content {
  display: block;
  margin: auto;
  margin-top: 100px;
  overflow: auto;
  max-height: 90vh;
}
</style>
